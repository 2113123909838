.msg-user-nm{
    font-size: 11px;
    position: absolute;
    top: 5px;
    right: 12px;
    color: #6c676766;
}

.doc-cmuncation-block{
    /* height: 761px; */
    max-height: 525px;
    overflow-y: auto;
    padding: 10px;
    margin-bottom: 2px;
    scrollbar-width: thin !important;
      /* scrollbar-color: var(--leftNavTxt) var(--pageHeaderBackground) !important; */
}

.doc-cmuncation-block::-webkit-scrollbar {
    width: 5px;
}

/* Thin scrollbar thumb */
.doc-cmuncation-block::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 4px;
}

.parnt-chatting .ant-avatar-lg {
    width: 58px !important;
    height: 58px !important;
}
.chat-datetime-position {
    position: relative;
    top: 11px;
}
.chat-sendbtn-icon{
    font-size: 32px !important;
}

.chat-sendbtn-icon:hover {
    margin-left: -12px !important;
    padding-bottom: 7px !important;
    margin-right: 5px !important;
    
}



.chat-btn-prnt .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fbc6c6e0 !important;
    background: #0000000f !important;
    width: 34px !important;
    height: 32px !important;
    margin-left: -2px !important;
    position: relative !important;
    left: 10px !important;
    top: 4px !important;
}

/* .chat-sendbtn-icon .ant-btn-text:disabled{
    color: #fbc6c6e0 !important;
} */