/* .hide-desc-label .ant-descriptions-item-label {
    display: none;
} */

/* .hide-desc-label .ant-descriptions.ant-descriptions-bordered >.ant-descriptions-view .ant-descriptions-row >.ant-descriptions-item-label {
    display: none;
} */

/* .hide-desc-label .ant-descriptions-item-content {
    flex: 1;
} */

.prjct-detail-prnt .ant-descriptions{
    width: 100% !important;
}