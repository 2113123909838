.usercard-txt{
    color: #717e9ebf;
    display: flex;
    align-items: center;
    font-size: 12px;
}
.usercard-col{
    background-color: #e4e9ee87;
    padding: 9px;
    border-radius: 8px;
}
.usercard-name{
    font-size: 13px;
    color: #34373982 !important;
}
.usercard-textpart{
    color: #717e9ebf;
}
.card-height{
    height:680px
}
.top-user-card-height{
    height: 506px
  }
@media (max-width: 767px) {
    .card-height {
      height: auto; /* Set the height to auto for mobile view */
    }
    .top-user-card-height{
        height: auto
      }
  }

  @media screen and (min-width: 1024px) {
    .top-user-card-height{
        height: auto
      }
      .card-height{
        height:auto
    }
   }
 
   @media screen and (min-width: 1200px) {
    .top-user-card-height{
        height: auto
      }
      .card-height{
        height:auto
    }
   }



.cmingsoon-part .error-wrapper {
  min-height: 310px !important;
}
 

 