/* .pdfviewer-prnt{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
} */

.pdfviewer-prntdiv {
    user-select: none !important;
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
  }