.ipformitem-space .ant-space-item{
    width: 100%;

}

.deviceformitem-list{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline !important;
}