.hdr-search-btn{
    position: relative;
    right: 41px;
    top: 1px;
}
.hdr-search-btn .ant-btn.ant-btn-circle.ant-btn-lg {
    padding-bottom: 16px !important;
}

@media (max-width: 767px) {
    .hdr-search-btn {
        display: none;
    }
}