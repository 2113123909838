.filtercardsection{
    padding: 0px 5px 25px 5px;
}
.filtertagbadge .ant-scroll-number{
    padding: 1px 0px 20px 7px;
    width: 0px;
    right: 21px;
}
.presentation-style{
    padding: 5px;
    padding-inline-start: 42px;
    display: flex;
    justify-content: flex-start;
}




.custom-doc-list-parent .ant-list-item {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: flex-end !important;
  align-items: flex-start !important;
}
.custom-doc-list-parent .ant-list-item-main {
  border-left: 2px solid #d5d5d51f;
  margin-left: 4px;
  padding-left: 10px;
}
.custom-doc-list-parent .ant-list-item-action {
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-end;
}
.custom-doc-list-parent .ant-list-vertical .ant-list-item .ant-list-item-extra {
  margin-inline-start: 14px !important;
}
.first-li{
display: flex;
  justify-content: center;
  align-items: center;
} 
.margin-first{
  margin-inline-end: 20px;
}
.margin-second{
  margin-inline-start: 0px;
}
.p-text{
  padding-bottom: 0px;
}

@media screen and (max-width: 576px){
  .custom-doc-list-parent .ant-list-vertical .ant-list-item .ant-list-item-extra {
    margin: auto auto;
  }
  .file-icon{
    margin-top: 4px;
  }
  .p-text{
    padding-bottom: 20px;
  }
  .margin-first{
    margin-inline-end: 0px;
    width: -webkit-fill-available;
  }
  .margin-second{
    /* margin-inline-start: -10px; */
    /* margin-inline-end: 0px;
    width: -webkit-fill-available; */
    margin-inline-start: 59px;
    margin-inline-end: 0px;
    /* background-color: red; */
    width: fit-content;
    display: flex;
  }
  .first-li{
      /* justify-content: center;
      align-items: center; */
      /* background: red; */
      display: block;
      position: absolute;
      left: 0px;
      /* justify-content: flex-start; */
      bottom: 25px;
      width: 287px;
  }
  .custom-doc-list-parent .ant-list-vertical .ant-list-item .ant-list-item-action >li:first-child{
      /* display: flex; */
      /* justify-content: center;
      align-items: center;
      background: red;
      position: absolute;
      left: 27px;
      justify-content: flex-start;
      bottom: 10px;
      width: 287px; */
      
  }
}

/* .custom-doc-list-parent .ant-list-vertical .ant-list-item .ant-list-item-action{
  background-color: #c93030;
  justify-content: flex-start;
  display: table-row-group;
} */
.custom-doc-list-parent .ant-list-vertical .ant-list-item .ant-list-item-action >li:first-child{
  display: flex;
  width: 100%;
}

 

/* .icon .custom-doc-list-parent .ant-list-vertical .ant-list-item .ant-list-item-extra{
background-color: #d5d5d51f;
} */


@media (max-width: 767px) {
    .presentation-style{
        padding: 5px;
        padding-inline-start: 5px;
        display: flex;
        justify-content: flex-start;
    }
  }
  .description-width{
    width: 290px;
  }

  @media screen and (min-width: 1024px) {
    .description-width{
        width: 350px;
      }
   }
 
   @media screen and (min-width: 1200px) {
    .description-width{
        width: 310px;
      }
   }