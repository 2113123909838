.docviewr-prnt .noSelect {
    -webkit-user-select: none !important;
    /* Safari */
    -moz-user-select: none !important;
    /* Firefox */
    -ms-user-select: none !important;
    /* IE10+/Edge */
    user-select: none !important;
    /* Standard syntax */
}

.docviewr-prnt #react-doc-viewer #header-bar {
    background-color: #292929;
}

.docviewr-prnt .my-doc-viewer-style {
    -webkit-user-select: none !important;
    /* Safari */
    -moz-user-select: none !important;
    /* Firefox */
    -ms-user-select: none !important;
    /* IE10+/Edge */
    user-select: none !important;
    /* Standard syntax */
}

.footer-taskbar-class {
    /* Update this to match the actual class or id of the footer taskbar */
    display: none !important;
}