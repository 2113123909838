@media (max-width: 768px) {
    .data-table-container .rdt_TableHead,
    .data-table-container .rdt_TableBody {
      overflow-x: auto;
      display: block;
    }
  
    .data-table-container .rdt_TableRow {
      display: flex;
      flex-wrap: wrap;
    }
  
    .data-table-container .rdt_TableCell {
      flex-grow: 1;
      flex-basis: auto;
    }
  }
  