/* .activity-prnt-segemnt .ant-segmented .ant-segmented-item {
    padding: 5px !important;
    background-color: #17a2b8 !important;
    color: white !important;
    border-color: #17a2b8 !important;
}

.activity-prnt-segemnt .ant-segmented .ant-segmented-item-selected,
.activity-prnt-segemnt .ant-segmented .ant-segmented-item:hover {
    background-color: #138496 !important;
    border-color: #117a8b !important;
}

.activity-prnt-segemnt .ant-segmented {
    border-radius: 20px !important;
}

.activity-prnt-segemnt .ant-segmented .ant-segmented-item {
    border-radius: 20px !important;
} */

.activity-prnt-segemnt .ant-segmented .ant-segmented-group {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-items: flex-start;
    width: 100%;
    margin-bottom: -7px;
}

.activity-prnt-segemnt .ant-segmented .ant-segmented-item:active:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled) {
    margin-bottom: 7px;
}