.doc-activty-block{
    height: 761px;
    overflow-y: auto;
    padding: 10px;
    margin-bottom: 2px;
    scrollbar-width: thin !important;
      /* scrollbar-color: var(--leftNavTxt) var(--pageHeaderBackground) !important; */
}

.doc-activty-block::-webkit-scrollbar {
    width: 5px;
}

/* Thin scrollbar thumb */
.doc-activty-block::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 4px;
}