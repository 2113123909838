.progress-dwnlimit{
    height: 266px;
}
.pgress-space{
    padding: 20px;
}
.dashbrd-slider{
    width: 90%;
    background: #d1def063;
}
.slider-fontflag{
    font-size: 22px;
}
.sliderprt-txt{
    font-weight: 600 !important;
    color: #8d8d93;
}
.sliderprt-txtsmall{
    color: #7a7a7e !important;
}

.manage-card-height{
    height:535px
}
.slider-hight{
    height: 230px;
}
.communication-card-hight{
    height: 761px;
}
/* .top-user-card-height{
    height: 506px
  } */
  .chat-section-card-height{
    max-height: 510px;
  }
  .manage-profile{
    height:535px
  }
@media (max-width: 767px) {
    .manage-card-height {
      height: auto; /* Set the height to auto for mobile view */
    }
    .manage-profile{
        height:auto
      }
    .slider-hight{
        height: auto;
    }
    .communication-card-hight{
        height: auto;
    }
    .chat-section-card-height{
        max-height: auto;
      }
    /* .top-user-card-height{
        height: auto
      } */
  }


  @media screen and (min-width: 1024px) {
   .manage-profile{
        height:auto
      }
  }

  @media screen and (min-width: 1200px) {
   .manage-profile{
        height:auto
      }
  }