.createuser-crd-parent .custom-card .card-header img {
    border-radius: 50%;
    margin-top: -295px;
    transform: scale(1.5);
}

.createuser-crd-parent .profile-details {
    margin-bottom: -20px;
}

.createuser-crd-parent .custom-card .card-footer > div {
    padding: 10px;
    text-align: center;
}

.createuser-crd-parent .custom-card {
    overflow: hidden;
    padding: 2px;
}

.user-table-header-main-view{
    padding: 5px;
    display: flex;
    justify-content: end;
}

.user-table-header-content-view{
    margin-top: 9px;
    margin-inline-end: 20px;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
}
.tag-style{
    margin-inline-end: 20px;
    margin-bottom: 5px;
}
.user-access-tag-style{
    margin-inline-end: 20px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
    .user-table-header-content-view {
      justify-content: flex-start; /* Adjust as needed */
    }
    .createuser-crd-parent .custom-card .card-header img {
        border-radius: 50%;
        margin-top: -50px;
        transform: scale(1.5);
    }
  }