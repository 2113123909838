.docdetail-counter .card .card-body {
    padding: 26px;
    background-color: transparent;
}

.docdetail-counter .server-widgets .bottom-server {
    margin: 30px 0;
}

.docdetail-downld-limit .card .card-body {
    padding: 35px;
    background-color: transparent;
    margin: 13px 0;
}
.docdtl-icn-color{
    color: #3766bd;
}
.docviewr-headr{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tmline-col{
    border-radius: 8px;
    display: flex;
    align-items: center !important;
    justify-content: space-between;
}
.tmline-name{
    font-size: 15px;
    color: #34373982 !important;
}
.tmline-name2{
    font-size: 14px;
    /* color: #7fd825db !important; */
}

.tnline-mainparent .ant-timeline .ant-timeline-item {
    margin-left: calc(-72% - 10px) !important;
}