.button-style{
    width:-webkit-fill-available; 
    margin-top:37px
  }
  .searchbutton{
    margin-top: -37px;
  }
@media (max-width: 767px) {
    .button-style{
      width: -webkit-fill-available; 
      margin-top: 0px
      }
      .searchbutton{
        margin-top: 0px;
        width: 100%;
      }
  }
  .serchdoc-btn{
    margin-top: 0.15rem !important;
  }

 