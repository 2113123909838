/* Parent container for app links */
.app-links {
  text-align: center;
  margin-top: 20px;
}

/* Anchor tag styles */
.app-links a {
  display: inline-block;
  margin: 10px; /* Spacing between buttons */
  text-decoration: none;
}

/* Image styles for badges */
.ios-app-img {
    /* background-color: white;
    padding: 2px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 60px;
    transition: transform 0.2s ease-in-out; */

    background-color: white;
    padding: 2px;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 60px;
    transition: transform 0.2s ease-in-out;
    width: 164px;
    height: 50px;
}

.android-app-img {
    background-color: white;
    padding: 0px;
    border-radius: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 60px;
    transition: transform 0.2s ease-in-out;
    width: 164px;
    height: 50px;
}

/* Hover effect for badges */
.app-links a img:hover {
  transform: scale(1.05); /* Slightly enlarge the badge on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add more shadow on hover */
}

  