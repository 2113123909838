.left-filter-block{
    height: 140px;
    overflow-y: auto;
    padding: 10px;
    margin-bottom: 2px;
    scrollbar-width: thin !important;
      /* scrollbar-color: var(--leftNavTxt) var(--pageHeaderBackground) !important; */
}

.left-filter-block::-webkit-scrollbar {
    width: 5px;
}

/* Thin scrollbar thumb */
.left-filter-block::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 4px;
}

.main-view{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -25px;
    width: 100%;
}
.first-content{
    display: flex;
    align-items: center;
    margin-inline-start: 10px;
    width: 72%;
}