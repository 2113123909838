.toggle-view{
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

@media (max-width: 768px) {
    .toggle-view{
        width: 70%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
    }
}
